import React from "react";
import {
    View,
    Text,
    StyleSheet,
    Platform,
    I18nManager,
    Animated,
    TouchableOpacity,
    Alert,
    Button,
    ScrollView,
    TextInput,
    TouchableHighlight,
    ActivityIndicator,
    Dimensions,
    FlatList,
    KeyboardAvoidingView
} from "react-native";

import Icon from 'react-native-vector-icons/Feather';

import { colors } from '../constants/colors';
import { fonts } from '../constants/fonts'

import { watchUsersData } from './../api/usersData'

import Modal from 'modal-enhanced-react-native-web';

import * as firebase from 'firebase';

import { useDispatch, useSelector, dispatch, connect } from 'react-redux'
import {  useNavigation, useLinkTo } from '@react-navigation/native'

function RegisterButton({ toggleModal }) {

  const linkTo = useLinkTo();

  const onPress = () => {
    toggleModal(),
    linkTo('/account/register')
  }

  return (
    <View style={{ flexDirection: "row", justifyContent: "center"}}>
      <Text style={{ paddingTop: 20, fontFamily: fonts.regular, color: colors.text}} adjustsFontSizeToFit allowFontScaling>Don't have an account?</Text>
      <TouchableOpacity
      style={{ paddingTop: 20}}
      onPress={onPress}
      >
      <Text style={{ textAlign: "center", fontFamily: fonts.regular, color: colors.textSecondary}} adjustsFontSizeToFit allowFontScaling> Register here.</Text>
      </TouchableOpacity>
    </View>
  )
}


class LoginModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      errorMessage: null,
      formLoading: false,
    }
  }


  resetError = () => {
    return this.setState({errorMessage: null})
  }

  handleLogin = async () => {
    this.setState({ formLoading: true })

    const {email, password} = this.state;

    await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        this.props.toggleLoginModal(),
        this.props.toggleCheckoutModal(),
        this.setState({ formLoading: false })
      })
      .catch(error => this.setState({errorMessage: error.message, formLoading: false }))

      setTimeout(
        this.props.watchUsersData(),
        2000
      );
  }

  signInGuest = () => {
    return (
      this.props.toggleLoginModal(),
      this.props.toggleGuestLogInModal()
    )
  }

  renderLoginModal = (isVisible, toggleLoginModal) => {
    return (
      <Modal
        animationIn={global.windowWidth > 768 ? "fadeIn" : "slideInUp"}
        animationOut={global.windowWidth > 768 ? "fadeOut" : "slideOutDown"}
        isVisible={this.props.isVisible}
        style={global.windowWidth > 768 ? styles.containerlWeb : styles.container}
        deviceHeight={Dimensions.get('window').height}
        deviceWidth={Dimensions.get('window').width}
      >
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
        >
        <View style={[global.windowWidth > 768 ? styles.modalWeb : styles.modal]}>

              <View style={[styles.header, { paddingHorizontal: 30, paddingTop: 30,}]}>
                <Text style={styles.heading} adjustsFontSizeToFit allowFontScaling>Login</Text>
                  <TouchableOpacity
                    onPress={() => toggleLoginModal()}
                    style={styles.headerClose}>
                    <Icon name="x" size={25} color={colors.textSecondary}/>
                </TouchableOpacity>
              </View>

              <View style={{ paddingTop: 20, paddingHorizontal: 30}}>
              {this.state.errorMessage ?
                <Text style={styles.errorMessage} adjustsFontSizeToFit allowFontScaling><Text style={{fontFamily: fonts.bold }}>Error</Text> {this.state.errorMessage}</Text>
              : null}

                <Text style={styles.subHeading} adjustsFontSizeToFit allowFontScaling>Email<Text style={styles.inputRequired}> *</Text></Text>
                <TextInput
                  style={styles.input}
                  autoCapitalize="none"
                  onChangeText={email => (this.setState({ email }, this.resetError()))}
                  value={this.state.email}
                  autoCompleteType="email"
                  ref={(input) => { this.input1 = input; }}
                  onSubmitEditing={() => { this.input2.focus(); }}
                ></TextInput>

                <Text style={styles.subHeading} adjustsFontSizeToFit allowFontScaling>Password<Text style={styles.inputRequired}> *</Text></Text>
                <TextInput
                  style={styles.input}
                  autoCapitalize="none"
                  autoCompleteType="password"
                  secureTextEntry
                  onChangeText={password => (this.setState({ password }, this.resetError()))}
                  value={this.state.password}
                  ref={(input) => { this.input2 = input; }}
                  ></TextInput>

                  <TouchableOpacity style={styles.button} onPress={this.handleLogin}>
                    {this.state.formLoading ?
                    <ActivityIndicator size="small" color={colors.text} />
                    : <Text style={styles.buttonText} adjustsFontSizeToFit allowFontScaling>Log In</Text> }
                  </TouchableOpacity>

                  <RegisterButton
                    toggleModal={() => toggleLoginModal()}
                  />

                <View style={{ borderTopWidth: 1, borderTopColor: colors.border, marginTop: 30}}>
                  <Text style={{
                      fontSize: 10,
                      letterSpacing: 1,
                      fontFamily: fonts.bold,
                      color: colors.text,
                      textAlign: "center",
                      paddingHorizontal: 10,
                      marginTop: -7,
                      alignSelf: "center",
                      position: "absolute",
                      textTransform: "uppercase",
                      backgroundColor: colors.white
                  }}>Or</Text>

                  <TouchableOpacity
                    style={[styles.buttonBorder, { marginBottom: 30, marginTop: 30, }]}
                    onPress={() => this.signInGuest()}
                  >
                    <Text style={[styles.buttonText, { color: colors.primary }]} adjustsFontSizeToFit allowFontScaling>Log in as Guest</Text>
                  </TouchableOpacity>

                </View>
              </View>
          </View>
          </KeyboardAvoidingView>
        </Modal>
    )
  }

  render() {
    return (
      <View>{this.renderLoginModal(this.props.isVisible, this.props.toggleLoginModal)}</View>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    watchUsersData: () => dispatch(watchUsersData()),
  };
}

export default connect(null, mapDispatchToProps)(LoginModal);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 0,
    justifyContent: "flex-end"
  },
  containerWeb: {
    flex: 1,
    margin: 0,
    alignContent: "center",
  },
  text: {
    color: colors.text,
    fontFamily: fonts.regular
  },
  modal: {
    minHeight: 290,
    backgroundColor:colors.white,
    width: Dimensions.get('window').width,
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
  },
  modalWeb: {
    minHeight: 290,
    backgroundColor:colors.white,
    minWidth: 500,
    maxWidth: 500,
    alignSelf: "center",
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
  },
  header: {
    flexDirection: "row",
    marginBottom: 10,
  },
  headerClose: {
    flex: 0.5,
    alignItems: "flex-end",
  },
  heading: {
    flex: 4,
    color: colors.text,
    fontSize: 22,
    fontFamily: fonts.bold
  },
  orderButton: {
    backgroundColor: colors.secondary,
    paddingVertical: 20,
    paddingHorizontal: 30,
    borderRadius: 40,
  },
  orderButtonDisabled: {
    backgroundColor: colors.secondary,
    opacity: 0.8,
    paddingVertical: 20,
    paddingHorizontal: 30,
    borderRadius: 40,
    marginTop: 20,
  },
  buttonText: {
    fontSize: 15,
    textAlign: "center",
    color: colors.primary,
    fontFamily: fonts.bold,
  },
  subHeading: {
    fontSize: 10,
    letterSpacing: 1,
    fontFamily: fonts.bold,
    color: colors.text,
    textTransform: "uppercase"
  },
  text: {
    alignItems: 'center',
    textAlign: "center",
  },
  button: {
    height: 58,
    backgroundColor: colors.secondary,
    paddingVertical: 20,
    paddingHorizontal: 30,
    borderRadius: 40,
    marginTop: 20,
  },
  buttonBorder: {
    height: 58,
    borderWidth: 2,
    borderColor: colors.primary,
    paddingVertical: 20,
    paddingHorizontal: 30,
    borderRadius: 40,
    marginTop: 20,
  },
  input: {
    fontSize: 15,
    fontFamily: fonts.regular,
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 25,
    borderBottomColor: colors.border,
    borderBottomWidth: 1,
  },
  inputRequired: {
    color: colors.red,
    fontFamily: fonts.bold,
  },
  errorMessage: {
    color: colors.white,
    padding: 10,
    backgroundColor: colors.red,
    marginBottom: 30,
  }
});
