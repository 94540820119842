import React from "react";
import {
    View,
    Text,
    StyleSheet,
    Platform,
    I18nManager,
    Animated,
    TouchableOpacity,
    Alert,
    Button,
    ScrollView,
    TextInput,
    TouchableHighlight,
    Dimensions,
    FlatList,
} from "react-native";

import Icon from 'react-native-vector-icons/Feather';
import { colors } from '../constants/colors';
import { fonts } from  '../constants/fonts';


import Modal from 'modal-enhanced-react-native-web';

import { useDispatch, useSelector} from 'react-redux'
import {  useNavigation } from '@react-navigation/native'

import * as firebase from 'firebase';

import SwitchSelector from "react-native-switch-selector";

import CustomerSelectAddress from "./CustomerSelectAddress";

import moment from "moment";


import { createOrder, resetCart } from './../api/orders'

import CartItem from './CartItem'

import NotesModal from './NotesModal'
import AddressModal from './AddressModal'
import TimeModal from './TimeModal'
import CheckoutModal from './CheckoutModal'
import AllergyModal from './AllergyModal'
import LoginModal from './LoginModal'
import GuestLogInModal from './GuestLogInModal'


function CheckoutButton({totalPrice, toggleCheckoutModal, cartType, postcode, toggleAddressModal, toggleLoginModal }) {

  const dispatch = useDispatch()
  const navigation = useNavigation()

  const cart = useSelector(state => state.cartData);
  const branch = useSelector(state => state.branchesData.selectedBranch);

  var user = firebase.auth().currentUser;

  const onPress = () => {
    if (cartType == 1 && postcode == "" || cartType == 1 && postcode == undefined) {
      toggleAddressModal()
    } else if (cart.customer.firstName == undefined &&
              cart.customer.secondName == undefined &&
              cart.customer.email == undefined &&
              cart.customer.phone == undefined && !user) {
      return toggleLoginModal()
    } else {
      return toggleCheckoutModal()
    }
  }

  return (
    <TouchableOpacity
      onPress={onPress}
      style={styles.orderButton}
      disabled={totalPrice >= branch.minimumOrder ? false : true}
    >

      {totalPrice >= branch.minimumOrder ?
        <Text style={styles.buttonText} adjustsFontSizeToFit allowFontScaling>Checkout {'\u22C5'} £{(+totalPrice).toFixed(2)}</Text>
      : <Text style={styles.buttonText} adjustsFontSizeToFit allowFontScaling>Add £{(+branch.minimumOrder-totalPrice).toFixed(2)} to Checkout {'\u22C5'} £{(+totalPrice).toFixed(2)}</Text>}
    </TouchableOpacity>
  )
}

function OrderTypeSwitch({ orderTypes, toggleAddressModal }) {
  const dispatch = useDispatch()

  const changeOrderType = (type) => {
    return {
      type: "UPDATE_ORDER_TYPE",
      payload: type,
    }
  }

  const onPress = (value) => {
    if (value == 1) {
      return (
        toggleAddressModal(),
        dispatch(changeOrderType(value))
      )
    } else {
      return (
        dispatch(changeOrderType(value))
      )
    }
  }
  return (
    <SwitchSelector
      initial={0}
      options={orderTypes}
      style={{paddingVertical: 10}}
      animationDuracton={50}
      hasPadding={true}
      animationDuration={10}
      buttonColor={colors.primary}
      textColor={colors.text}
      textStyle={{fontSize: 13, fontFamily: fonts.regular}}
      selectedTextStyle={{ color: colors.white, fontSize: 13, fontFamily: fonts.regular }}
      onPress={value => onPress(value)}
    />
  )
}

class CartModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      postcode: "",
      note: "",
      isTimeModalVisible: false,
      isCheckoutModalVisible: false,
      isAllergenModalVisible: false,
      isLoginModalVisible: false,
      isGuestLogInModalVisible: false,
      orderTypes: [],
    }
  }


  componentDidMount() {

    let orderTypes = []
    // Finds whether branch does collection and delivery and assigns into array for SwitchSeelector
    if (this.props.branchData.isDelivery == true) {
      orderTypes.push({ label: "Delivery", value: 1 })
    }

    if (this.props.branchData.isCollection == true) {
      orderTypes.push({ label: "Collection", value: 2 })
    }
    this.setState(state => ({
      orderTypes: orderTypes,
    }))

    this.props.setOrderType(orderTypes[0].value)

  }

  componentDidUpdate(prevProps) {
    if (prevProps.branchData !== this.props.branchData) {

      let orderTypes = []
      // Finds whether branch does collection and delivery and assigns into array for SwitchSeelector
      if (this.props.branchData.isDelivery == true) {
        orderTypes.push({ label: "Delivery", value: 1 })
      }

      if (this.props.branchData.isCollection == true) {
        orderTypes.push({ label: "Collection", value: 2 })
      }
      this.setState(state => ({
        orderTypes: orderTypes,
      }))

      this.props.setOrderType(orderTypes[0].value)
    }
}

  toggleAddressModal = () => {
    this.setState(state => ({
      isAddressModalVisible: !state.isAddressModalVisible
    }))
  }

  toggleCheckoutModal = () => {
    this.setState(state => ({
      isCheckoutModalVisible: !state.isCheckoutModalVisible
    }))
  }

  toggleAllergyModal = () => {
    this.setState(state => ({
      isAllergenModalVisible: !state.isAllergenModalVisible
    }))
  }

  toggleLoginModal = () => {
    this.setState(state => ({
      isLoginModalVisible: !state.isLoginModalVisible
    }))
  }

  toggleGuestLogInModal = () => {
    this.setState(state => ({
      isGuestLogInModalVisible: !state.isGuestLogInModalVisible
    }))
  }

  renderSeparatorView = () => {
    return (
      <View style={{
          height: 1,
          width: "100%",
          backgroundColor: "#DEDEDE",
        }}
      />
    );
  };

  renderCategoriesList = ( { item }) => {
    return (
      <CategoryItem category={item} />
    )
  }

  renderItemsList = ( { item, index, removeItem }) => {
    return (
      <CartItem item={item} index={index} removeItem={() => this.props.removeItem(item, index)} />
    )
  }


  renderCartModal = (isVisible, toggleCartModal, toggleCheckoutModal, cartData, branchData, deliveriesData, removeItem, postcode, usersData, addOrderNote, addOrderDiscount) => {

    let cartTotal = 0

    cartData.items.map(item => cartTotal += item.variant ?
      item.damount ? item.dtype == "amount" ? item.variant.price * item.quantity - item.damount : (item.variant.price * item.quantity) - ((item.variant.price * item.quantity) / 100 * item.damount)
      : item.variant.price * item.quantity
      : item.damount ? item.dtype == "amount" ? item.price * item.quantity - item.damount : (item.price * item.quantity) - ((item.price * item.quantity) / 100 * item.damount)
      : item.price * item.quantity
    );

    if (cartData.time != null) {
      var timeSlot = moment(cartData.time).format("ddd Do MMM - HH:mm")
    }

    return (
        <View style={Platform.OS == "web" && Dimensions.get('window').width < 768 ? styles.modalWeb : styles.modal}>
        <View style={styles.header}>
          <Text style={styles.heading} adjustsFontSizeToFit allowFontScaling>Your Order</Text>

          {Platform.OS == "web" && Dimensions.get('window').width < 768 ?
          <TouchableOpacity
            onPress={() => toggleCartModal()}
            style={styles.headerClose}>
            <Icon name="x" size={25} color={colors.textSecondary}/>
          </TouchableOpacity>
          : null }
        </View>

        <ScrollView style={{ maxHeight: Dimensions.get('window').height  / 2,}}>
        <OrderTypeSwitch
          orderTypes={this.state.orderTypes}
          toggleAddressModal={this.toggleAddressModal}
          isCollection={branchData.isCollection}
          isDelivery={branchData.isDelivery}
        />

        <View style={styles.items}>
        <FlatList
          data={cartData.items}
          keyExtractor={(item, index) => item.id.toString()}
          ItemSeparatorComponent={this.renderSeparatorView}
          ListFooterComponent={this.renderSeparatorView}
          renderItem={(item, index, removeItem) => this.renderItemsList(item, index, removeItem)}
          initialNumToRender={8}
          maxToRenderPerBatch={8}
          windowSize={8}
        />

        {cartData.items.length < 1 ?
          <Text style={{ fontSize: 13, textAlign: "center", color: colors.textSecondary, paddingTop: 20, fontFamily: fonts.regular }}>Your order is empty.</Text>
        : null }

        </View>

      </ScrollView>

      <View style={styles.orderDetails}>

      <TouchableOpacity
        onPress={() =>this.toggleAddressModal()}
        disabled={cartData.type == 2 ? true : false}
        style={styles.orderType}>
        <Icon name="navigation" style={styles.orderTypeIcon} size={25} color={colors.text} light/>
        {cartData.type == 2 ?
        <View style={styles.orderTypeText}>
          <Text style={{ fontSize: 14, fontFamily: fonts.regular }} adjustsFontSizeToFit allowFontScaling>Collection</Text>
          <Text style={{color: colors.textSecondary, fontSize: 13, fontFamily: fonts.regular }} adjustsFontSizeToFit allowFontScaling>{branchData.line1}, {branchData.town}, {branchData.postCode}.</Text>
        </View>
        :
        <View style={styles.orderTypeText}>
          <Text style={{ fontSize: 14, fontFamily: fonts.regular }} adjustsFontSizeToFit allowFontScaling>Delivery</Text>
          {cartData.customer.postcode ?
            <Text numberOfLines={1} style={{color: colors.textSecondary, fontSize: 13, fontFamily: fonts.regular }} adjustsFontSizeToFit allowFontScaling>{cartData.customer.line1}, {cartData.customer.town}, {cartData.customer.postcode}.</Text>
            : <Text style={{color: colors.textSecondary, fontSize: 13, fontFamily: fonts.regular }} adjustsFontSizeToFit allowFontScaling>Add Delivery Address</Text>
          }
        </View>}

        <Icon name="arrow-right" style={styles.orderTypeOpen} size={24} color={cartData.type == 1 ? colors.border : colors.white}/>
      </TouchableOpacity>

            </View>

      {cartData.items.length > 0 ?
      <CheckoutButton
        totalPrice={cartTotal}
        postcode={cartData.customer.postcode}
        cartType={cartData.type}
        toggleAddressModal={() => this.toggleAddressModal()}
        toggleCheckoutModal={() => this.toggleCheckoutModal()}
        toggleLoginModal={() => this.toggleLoginModal()}
        toggleOrderReceivedModal={this.props.toggleOrderReceivedModal}
      />

            : null}

      <NotesModal
        cartData={cartData}
        addOrderNote={addOrderNote}
        toggleNotesModal={() => this.toggleNotesModal()}
        isVisible={this.state.isNotesModalVisible}
      />

      <AllergyModal
        allergyNotes={this.props.branchData.allergyNotes}
        toggleAllergyModal={() => this.toggleAllergyModal()}
        isVisible={this.state.isAllergenModalVisible}
      />

      <LoginModal
        toggleCheckoutModal={() => this.toggleCheckoutModal()}
        toggleLoginModal={() => this.toggleLoginModal()}
        toggleGuestLogInModal={() => this.toggleGuestLogInModal()}
        isVisible={this.state.isLoginModalVisible}
      />

      <GuestLogInModal
        toggleCheckoutModal={() => this.toggleCheckoutModal()}
        toggleLoginModal={() => this.toggleLoginModal()}
        toggleGuestLogInModal={() => this.toggleGuestLogInModal()}
        isVisible={this.state.isGuestLogInModalVisible}
      />

      <AddressModal
        cartData={cartData}
        userData={usersData}
        deliveriesData={deliveriesData}
        toggleAddressModal={() => this.toggleAddressModal()}
        isVisible={this.state.isAddressModalVisible}
      />

      <TimeModal
        cartData={cartData}
        hoursData={this.props.hoursData}
        prepareTime={this.props.branchData.prepareTime}
        isPreOrder={this.props.branchData.isPreOrder}
        maxPreOrderDays={this.props.branchData.maxPreOrderDays}
        toggleTimeModal={() => this.toggleTimeModal()}
        isVisible={this.state.isTimeModalVisible}
      />

      <CheckoutModal
        formLoading={this.state.formLoading}
        subTotal={cartTotal}
        userData={usersData}
        cartData={this.props.cartData}
        branchData={branchData}
        hoursData={this.props.hoursData}
        addOrderNote={addOrderNote}
        addOrderDiscount={addOrderDiscount}
        setPaymentType={this.props.setPaymentType}
        toggleCheckoutModal={() => this.toggleCheckoutModal()}
        toggleCartModal={() => toggleCartModal()}
        toggleOrderReceivedModal={this.props.toggleOrderReceivedModal}
        isVisible={this.state.isCheckoutModalVisible}
        prepareTime={branchData.prepareTime}
        deliveryTime={branchData.deliveryTime}
        isPreOrder={branchData.isPreOrder}
        maxPreOrderDays={branchData.maxPreOrderDays}
      />

      </View>
    )
  }

  render() {

    if (Platform.OS == "web" && Dimensions.get('window').width < 768) {
    return (
      <Modal
        animationIn="slideInUp"
        animationOut="slideOutDown"
        isVisible={this.props.isCartVisible}
        style={styles.container}
        deviceHeight={Dimensions.get('window').height}
        deviceWidth={Dimensions.get('window').width}
      >
        {this.renderCartModal(this.props.isCartVisible, this.props.toggleCartModal, this.props.toggleCheckoutModal, this.props.cartData, this.props.branchData, this.props.deliveriesData, this.props.removeItem, this.state.postcode, this.props.usersData, this.props.addOrderNote, this.props.addOrderDiscount)}
      </Modal>
    )
  } else {
    return (
      <View style={{ position: "sticky", top: 80, right: 0, elevation: 8, zIndex: 10 }}>
        {this.renderCartModal(this.props.isCartVisible, this.props.toggleCartModal, this.props.toggleCheckoutModal, this.props.cartData, this.props.branchData, this.props.deliveriesData, this.props.removeItem, this.state.postcode, this.props.usersData, this.props.addOrderNote, this.props.addOrderDiscount)}
        <View>
          <TouchableOpacity
            onPress={this.toggleAllergyModal}
          >
            <Text style={styles.allergyLink}>Allergen and Dietary Information</Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  }
}

export default CartModal;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 0,
  },
  text: {
    color: colors.text,
  },
  modal: {
    flex: 1,
    marginTop: 50,
    borderWidth: 1,
    borderColor: colors.border,
    padding: 30,
  },
  modalWeb: {
    flex: 1,
    backgroundColor:colors.white,
    height: Dimensions.get('window').height,
    width: Dimensions.get('window').width,
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    marginTop: 120,
    padding: 30,
  },
  header: {
    flexDirection: "row",
    marginBottom: 10,
  },
  headerClose: {
    flex: 0.5,
    alignItems: "flex-end",
  },
  heading: {
    flex: 4,
    color: colors.text,
    fontSize: 22,
    fontFamily: fonts.bold
  },
  orderButton: {
    backgroundColor: colors.secondary,
    paddingVertical: 20,
    paddingHorizontal: 30,
    borderRadius: 40,
    marginTop: 20,
  },
  orderButtonDisabled: {
    backgroundColor: colors.secondary,
    opacity: 0.8,
    paddingVertical: 20,
    paddingHorizontal: 30,
    borderRadius: 40,
    marginTop: 20,
  },
  buttonText: {
    fontSize: 15,
    textAlign: "center",
    color: colors.primary,
    fontFamily: fonts.bold,
  },
  items: {
    flex: 5,
    marginVertical: 20,
    overflow: "visible"
  },
  receiptDetails: {
    flex: 0.5,
    borderTopWidth: 1,
    borderTopColor: colors.border
  },
  orderDetails: {
    borderTopWidth: 1,
    borderTopColor: colors.border,
  },
  orderType: {
    flex: 1,
    flexDirection: "row",
    paddingVertical: 15,
    borderBottomWidth: 1,
    borderBottomColor: colors.border,
  },
  orderTypeIcon: {
    flex: 0.5,
    marginTop: 5,
    marginRight: 15,
  },
  orderTypeOpen: {
    flex: 0.5,
    marginRight: 15,
    alignItems: "flex-end",
    textAlign: "right"
  },
  orderTypeText: {
    flex: 5,
  },
  receiptDetails: {
    flex: 1.5,
  },
  subHeading: {
    fontSize: 12,
    letterSpacing: 2,
    marginVertical: 10,
    textTransform: "uppercase",
    fontWeight: "700"
  },
  findPostcodeInput: {
    flex: 4,
    paddingVertical: 20,
    paddingHorizontal: 20,
    borderWidth: 1,
    borderColor: colors.border
  },
  findPostcodeButton: {
    flex: 1,
    paddingVertical: 20,
    paddingHorizontal: 20,
    backgroundColor: colors.primary,
  },
  allergyLink: {
    color: colors.textSecondary,
    fontSize: 12,
    paddingHorizontal: 10,
    paddingVertical: 20,
    fontFamily: fonts.regular
  }
});
