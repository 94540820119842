import React, { useState } from "react";
import "../styles.css";
function Maps() {

  return (
    <>
    <div class="section-maps">
      <div class="container mt-5">
        <div class="row">

          <div class="col-md-4">
            <h2>Find Us</h2>
            <p>121 Gladstone St, <br />Darlington, DL3 6LA</p>

            <a href="tel:+441325 288139" class="phone-link">01325 288139</a>

            <br />
            <br />
            <br />

            <h2>Hours</h2>
              <p>
                Mon-Sun: 5pm – 11:30pm<br />
              </p>
          </div>
          <div class="col-md-8">
            <iframe
            class="map"
            title="Bengal Heritage's Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2315.267402016157!2d-1.5602338870780332!3d54.52878037254176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487e9bc69a8aaac5%3A0xa6c6f3f3c80d1ee4!2sBengal%20Heritage!5e0!3m2!1sen!2suk!4v1723841145081!5m2!1sen!2suk"
            width="100%"
            height="450"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default Maps;