import React from "react";
import {
    View,
    Text,
    StyleSheet,
    Platform,
    I18nManager,
    Animated,
    TouchableOpacity,
    Alert,
    Button,
    ScrollView,
    TextInput,
    TouchableHighlight,
    Dimensions,
    FlatList,
    KeyboardAvoidingView
} from "react-native";

import Icon from 'react-native-vector-icons/Feather';
import { colors } from '../constants/colors';
import { fonts } from  '../constants/fonts';

import Modal from 'modal-enhanced-react-native-web';

import { useDispatch, useSelector} from 'react-redux'
import {  useNavigation } from '@react-navigation/native'


class ClosedModal extends React.Component {

  constructor(props) {
    super(props);
  }

  renderClosedModal = (isVisible, toggleClosedModal) => {
    return (
      <Modal
        animationIn={global.windowWidth > 768 ? "fadeIn" : "slideInUp"}
        animationOut={global.windowWidth > 768 ? "fadeOut" : "slideOutDown"}
        isVisible={this.props.isVisible}
        style={global.windowWidth > 768 ? styles.containerlWeb : styles.container}
        deviceHeight={Dimensions.get('window').height}
        deviceWidth={Dimensions.get('window').width}
      >
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
        >
        <View style={[global.windowWidth > 768 ? styles.modalWeb : styles.modal]}>

              {!this.props.branchData.isBusy ?

              <View style={{ paddingTop: 10, paddingHorizontal: 30}}>

                <View style={styles.header}>
                  <Text style={styles.heading}>We're closed now!</Text>
                </View>

                <Text style={styles.text}>You can still place a preorder for when we're next open.</Text>

                <TouchableOpacity
                  onPress={() => toggleClosedModal()}
                  style={styles.orderButton}
                >
                  <Text style={styles.buttonText} adjustsFontSizeToFit allowFontScaling>Close</Text>
                </TouchableOpacity>

              </View>

              :

              <View style={{ paddingTop: 10, paddingHorizontal: 30}}>

                <View style={styles.header}>
                  <Text style={styles.heading}>We're Very Busy!</Text>
                </View>

                <Text style={styles.text}>We've paused orders for now as we're very busy - please check back later.</Text>
                <Text style={[styles.text, { marginTop: 20 }]}>If you'd like to know more please call us on {this.props.branchData.phone}.</Text>

                <TouchableOpacity
                  onPress={() => toggleClosedModal()}
                  style={styles.orderButton}
                >
                  <Text style={styles.buttonText} adjustsFontSizeToFit allowFontScaling>Close</Text>
                </TouchableOpacity>

              </View>}
          </View>
          </KeyboardAvoidingView>
        </Modal>
    )
  }

  render() {
    return (
      <View>{this.renderClosedModal(this.props.isVisible, this.props.toggleClosedModal)}</View>
    )
  }
}

export default ClosedModal;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 0,
    justifyContent: "flex-end"
  },
  containerWeb: {
    flex: 1,
    margin: 0,
    alignContent: "center",
  },
  text: {
    color: colors.text,
    textAlign: "center"
  },
  modal: {
    paddingVertical: 30,
    minHeight: 190,
    backgroundColor:colors.white,
    width: Dimensions.get('window').width,
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
  },
  modalWeb: {
    paddingVertical: 30,
    minHeight: 190,
    backgroundColor:colors.white,
    minWidth: 600,
    maxWidth: 600,
    alignSelf: "center",
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
  },
  header: {
    flexDirection: "row",
    marginBottom: 10,
  },
  headerClose: {
    flex: 0.5,
    alignItems: "flex-end",
  },
  heading: {
    flex: 4,
    textAlign: "center",
    color: colors.text,
    fontSize: 22,
    fontFamily: fonts.bold,
  },
  orderButton: {
    height: 58,
    backgroundColor: colors.secondary,
    paddingVertical: 20,
    paddingHorizontal: 30,
    marginTop: 20,
    borderRadius: 40,
  },
  buttonText: {
    fontFamily: fonts.bold,
    fontSize: 15,
    marginTop: -2,
    textAlign: "center",
    color: colors.primary
  },
  items: {
    flex: 5,
    paddingVertical: 20,
  },
  receiptDetails: {
    flex: 0.5,
    borderTopWidth: 1,
    borderTopColor: colors.border
  },
  orderDetails: {
    flex: 2,
    borderTopWidth: 1,
    borderTopColor: colors.border
  },
  orderType: {
    flexDirection: "row",
    paddingVertical: 15,
    borderBottomWidth: 1,
    borderBottomColor: colors.border
  },
  orderTypeIcon: {
    marginTop: 5,
    marginRight: 15,
  },
  orderTypeOpen: {
    marginTop: 5,
    marginRight: 15,
    alignContent: "flex-end"
  },
  orderTypeText: {
  },
  receiptDetails: {
    flex: 1.5,
  },
  subHeading: {
    fontSize: 12,
    letterSpacing: 2,
    marginVertical: 10,
    textTransform: "uppercase",
    fontWeight: "700"
  },
  findPostcodeInput: {
    flex: 4,
    paddingVertical: 20,
    paddingHorizontal: 20,
    borderWidth: 1,
    borderColor: colors.border
  },
  findPostcodeButton: {
    flex: 1,
    paddingVertical: 20,
    paddingHorizontal: 20,
    backgroundColor: colors.primary,
  }
});
